<template>
    <div class="pagination justify-between flex mt-5 p-4" v-if="paginatedata.data">
        <div class="pagination-total">From <span>{{ paginatedata.from}}</span>  to <span>{{ paginatedata.from}}</span> Results  of  <span>{{ paginatedata.total}}</span> </div>
        <div class="flex">
            <span class="pagination-pager pagination-pager-prev" :class="{'pagination-pager-disabled' : paginatedata.current_page == 1 }" @click.prevent="get_next_page_data(paginatedata.current_page - 1)">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
            </svg>
            </span>
            <ul>
                <li class="pagination-pager " style="margin-right:10px">Current </li>
                <li class="pagination-pager active">{{ paginatedata.current_page }}</li>
                <li class="pagination-pager">Of</li>
                <li class="pagination-pager">{{  paginatedata.last_page  }}</li>
            </ul>
            <span class="pagination-pager pagination-pager-next" @click.prevent="get_next_page_data(paginatedata.current_page + 1)" :class="{'pagination-pager-disabled' : paginatedata.last_page == paginatedata.current_page }">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                </svg>
            </span>
        </div>
    </div>  
    </template>
    <script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            paginatedata: {
                type: Object,
                required: true
            },
    
            current_page:{
                type: Number,
                default: 1
            }
        },
    
        computed:{
            ...mapGetters(['loading'])
        },
    
        methods: {
            get_next_page_data(newPage){
                if (newPage < 1 || newPage > this.paginatedata.last_page) return;
                this.$emit('page_changed', newPage);
            },
    
      
        },
    }
    </script>