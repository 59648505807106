export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
   state: {
       shoporders: '',
       shoporder: '', 
   },
   
   getters: {
       shoporders: state => state.shoporders,
       shoporder: state => state.shoporder,
   },

   mutations: { 
       setshoporders: (state, shoporders) => (state.shoporders = shoporders), 
       setshoporder: (state, shoporder) => (state.shoporder = shoporder), 
   },

   actions: {
       async get_shoporders({ commit }, {data, page}) {
           commit('seterror', '')
           commit('setmsg', '')
           commit('setloader', 'get_shoporders')
           try {
               const response = await axios.post(`/admin/educanshop?page=${page}`, data)
               commit('setloader', false)
               if(response.data) {
                   commit('setshoporders', response.data.res)
               }
               return response
           } catch (error) {
               commit('setloader', false)
               handleError(commit, error)
               throw error
           }
       },

       async get_shoporder_details({ commit }, {data, id}) {
           commit('seterror', '')
           commit('setmsg', '')
           commit('setloader', `get_shoporder_details${id}`)
           try {
               const response = await axios.post(`/admin/educanshop/${id}`, data)
               commit('setloader', false)
               if(response.data) {
                   commit('setshoporder', response.data.res)
               }
               return response
           } catch (error) {
               commit('setloader', false)
               handleError(commit, error)
               throw error
           }
       },

       async update_shoporder_status({ commit }, {data, id}) {
           commit('seterror', '')
           commit('setmsg', '')
           commit('setloader', `update_order_status${id}`)
           try {
               const response = await axios.post(`/admin/educanshop/status/${id}`, data)
               commit('setloader', false)
               if(response.data) {
                   commit('setshoporder', response.data.res)
                   toast.success("Order status updated successfully")
               }
               return response
           } catch (error) {
               commit('setloader', false)
               handleError(commit, error)
               throw error
           }
       },

       async cancel_shoporder({ commit }, {data, id}) {
           commit('seterror', '')
           commit('setmsg', '')
           commit('setloader', `cancel_order${id}`)
           try {
               const response = await axios.post(`/admin/educanshop/cancel/${id}`, data)
               commit('setloader', false)
               if(response.data) {
                   commit('setshoporder', response.data.res)
                   toast.success("Order cancelled successfully")
               }
               return response
           } catch (error) {
               commit('setloader', false)
               handleError(commit, error)
               throw error
           }
       },

       async delete_shoporder({ commit }, {data, id}) {
           commit('seterror', '')
           commit('setmsg', '')
           commit('setloader', `delete_order${id}`)
           try {
               const response = await axios.post(`/admin/educanshop/delete/${id}`, data)
               commit('setloader', false)
               if(response.data) {
                   toast.success("Order deleted successfully") 
               }
               return response
           } catch (error) {
               commit('setloader', false)
               handleError(commit, error)
               throw error
           }
       }
   }
}

function handleError(commit, error) {
   if (error.response?.data) {
       if (error.response.data.msg) {
           commit('seterror', error.response.data.msg)
           toast.error(error.response.data.msg)
       } else if (error.response.data.message) {
           commit('seterrors', error.response.data.message)
       }
   }
}