import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('educanToken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
      if (store.getters.authuser.role == 'admin') {
        return next()
      } else {
        return next({ name: 'login' })
      }
    }else{
        return next({ name: 'login' })
    }
};


let routes = [{
  path: "/",
  props: true,
  beforeEnter: adminguard,
  component: () => import("@/views/app/main.vue"),
  children: [
    // ============================================== Dashboard ============================================
    {
      path: "/",
      name: 'dashboard',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/dashboard/main.vue"),
      meta: {
        title: 'Dashboard',
      },
    },

    {
      path: "/entrants",
      name: 'entrants_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/entrants/main.vue"),
      children: [
        {
          path: "",
          name: 'entrants',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/entrants/entrants.vue"),
          meta: {
            title: 'Data Entrants',
          },
        },
      ]
    },


    {
      path: "/moderators",
      name: 'moderators_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/moderators/main.vue"),
      children: [
        {
          path: "",
          name: 'moderators',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/moderators/moderators.vue"),
          meta: {
            title: 'Data Moderators',
          },
        },
      ]
    },

    {
      path: "/students",
      name: 'student_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/students/main.vue"),
      children: [
        {
          path: "",
          name: 'students',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/students/students.vue"),
          meta: {
            title: 'Students',
          },
        },
      ]
    },

    {
      path: "/teachers",
      name: 'teacher_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/teachers/main.vue"),
      children: [
        {
          path: "",
          name: 'teachers',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/teachers/teachers.vue"),
          meta: {
            title: 'Teachers',
          },
        },

        {
          path: "requests",
          name: 'teacher_requests',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/teachers/requests.vue"),
          meta: {
            title: 'Teacher Requests',
          },
        },

        {
          path: ":id",
          name: 'teacher_details',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/teachers/details.vue"),
          meta: {
            title: 'Teacher Details',
          },
        },
      ]
    },


    {
      path: "/help_centers",
      name: 'help_center_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/help_center/main.vue"),
      children: [
        {
          path: "",
          name: 'help_center',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/help_center/center.vue"),
          meta: {
            title: 'Help Center Personnel',
          },
        },
      ]
    },



    {
      path: "/users",
      name: 'users',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/users/main.vue"),
      children:[

        {
          path: "",
          name: 'users_dash',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/users/users_dash.vue"),
          meta: {
            title: 'Manage Users',
          },
        },

        {
          path: "students",
          name: 'student_users',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/users/student_users.vue"),
          meta: {
            title: 'Manage Students',
          },
        },

      ]
    },

    {
      path: "/account",
      name: 'account',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/account/main.vue"),
      children: [
        {
          path: "profile",
          name: 'profile',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/account/profile.vue"),
          meta: {
            title: 'Profile',
          },
        },
      ]
    },


    {
      path: "/notifications",
      name: '',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/notifications/main.vue"),
      children: [
        {
          path: "",
          name: 'notifications',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/notifications/notifications.vue"),
          meta: {
            title: 'notifications',
          },
        },
      ]
    },


    //consultations
    {
      path: "/consultations",
      name: '',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/consults/main.vue"),
      children: [
        {
          path: "",
          name: 'consults_dashboard',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/consults/consults_dashboard.vue"),
          meta: {
            title: 'consultations',
          },
        },
        
        {
          path: "new",
          name: 'consultations',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/consults/consultations.vue"),
          meta: {
            title: 'consultations',
          },
        },
        
        {
          path: "waiting-teachers",
          name: 'waiting_teachers',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/consults/waiting_teachers.vue"),
          meta: {
            title: 'consultations',
          },
        },

        {
          path: "approved-consults",
          name: 'approved_consults',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/consults/approved_consults.vue"),
          meta: {
            title: 'consultations',
          },
        },

        {
          path: "in-progress-consults",
          name: 'inprogress_consults',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/consults/inprogress_consults.vue"),
          meta: {
            title: 'consultations',
          },
        },
        
      ]
    },

    //products and Sales
    
    {
      path: "/shop",
      name: 'shop_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/shop/main.vue"),
      children: [
        
        {
          path: "",
          name: 'products_dashboard',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/products_dashboard.vue"),
          meta: {
            title: 'Educan Shop Dashbaord',
          },
        },

        {
          path: "shop",
          name: 'e_commerce',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/shop.vue"),
          meta: {
            title: 'Educan Sales Personnel',
          },
        },
        

        {
          path: "new-orders",
          name: 'new_shop_orders',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/new_shop_orders.vue"),
          meta: {
            title: 'Products Inventory',
          },
        },
        {
          path: "pending-orders",
          name: 'pending_shop_orders',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/pending_shop_orders.vue"),
          meta: {
            title: 'Products Inventory',
          },
        },

        {
          path: "complete-orders",
          name: 'complete_shop_orders',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/complete_shop_orders.vue"),
          meta: {
            title: 'Products Inventory',
          },
        },

        {
          path: "canceled-orders",
          name: 'canceled_shop_orders',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/canceled_shop_orders.vue"),
          meta: {
            title: 'Products Inventory',
          },
        },

        {
          path: "order-details/:id",
          name: 'product_order_details',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/order_details.vue"),
          meta: {
            title: 'Product Order Details',
          },
        },

        {
          path: "inventory",
          name: 'products_inventory',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/products_inventory.vue"),
          meta: {
            title: 'Products Inventory',
          },
        },
        

        
      ]
    },

  ]
}]
export default routes;