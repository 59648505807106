import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import "@/assets/app_css.css"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import loadingBtn from '@/views/comps/loadingbtn'
import dataLoader from '@/views/comps/dataloader'
import pagination from '@/views/comps/pagination'
import moment from 'moment'


import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '7dc85893a1b1f0775552',
    cluster: 'mt1',
    encrypted: true
})

const app = createApp(App).use(store).use(router).use(Toast)

app.config.globalProperties.$filters = {
    truncate(value, num) {
        if (value.length > num) {
            return value.substring(0, num) + '...';
        }
        return value;
    },

    timeAgo(date) {
        return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    countconvert(value){
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + 'm';
          } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
          } else {
            return value.toFixed(1);
          }
    },
}

function load_js_files() {
    var scripts = [
        // "/assets/js/vendors.min.js",
    ];
    scripts.forEach((script) => {
    let tag = document.createElement("script");
    tag.setAttribute("src", script);
    document.head.appendChild(tag);
    })
}
load_js_files(); 
setInterval(load_js_files, 10000);

app.component('pagination', pagination).component('loadingBtn', loadingBtn).component('dataLoader', dataLoader).mount('#app')
