<template>
    <!-- <button :class="['btn btn-disabled', className]" v-if="loadingvalue != '' && loadingvalue === loading">
        <span class="flex items-center justify-center">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 16 16" class="animate-spin text-primary-600 h-[40px] w-[40px]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0c-4.355 0-7.898 3.481-7.998 7.812 0.092-3.779 2.966-6.812 6.498-6.812 3.59 0 6.5 3.134 6.5 7 0 0.828 0.672 1.5 1.5 1.5s1.5-0.672 1.5-1.5c0-4.418-3.582-8-8-8zM8 16c4.355 0 7.898-3.481 7.998-7.812-0.092 3.779-2.966 6.812-6.498 6.812-3.59 0-6.5-3.134-6.5-7 0-0.828-0.672-1.5-1.5-1.5s-1.5 0.672-1.5 1.5c0 4.418 3.582 8 8 8z"></path>
            </svg>
            {{ loadingTxt }}
        </span>
    </button> -->

    <button :class="['btn btn-btn btn-default', className]" class="" v-if="loadingvalue != '' && loadingvalue === loading">
        <span class="flex items-center justify-center">
            <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="animate-spin mr-1 w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path>
                <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="currentColor"></path>
            </svg>
            {{ loadingTxt }}
        </span>
    </button>

    <button v-else :class="['btn', className]" >
        <span class="flex items-center justify-center">
            <i :Class="iconData" style="margin-right: 10px; font-size: 14px;"></i>
            {{  btnText }}
        </span>
    </button>
    </template>
    <script>
    import { mapGetters } from 'vuex'
    export default {
    
        props: {
            
            loadingvalue: {
                type: [String, Boolean, Number],
                default: ''
            },
            loadingTxt: {
                type: String,
                default: 'Loading...'
            },
            className: {
                type: String,
                default: 'btn btn bg-emerald-500 hover:bg-emerald-400 active:bg-emerald-600 text-white mr-2'
            },
            btnText: {
                type: String,
                default: 'Submit'
            },
            iconData: {
                type: String,
                default: ''
            }
        },
    
        computed:{
            ...mapGetters(['loading'])
        },
    
        methods: {
            handleClick() {
                if(!this.loading) {
                this.onClick()
                }
            }
        },
    }
    </script>