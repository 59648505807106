export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        consultations :'',
        consultation :'', 
        consultteachers :'', 
    },
    
    getters : {
        consultations: (state )=> state.consultations,
        consultation: (state )=> state.consultation,
        consultteachers: (state )=> state.consultteachers,
    },


    mutations : { 
        setconsultations :(state,consultations ) => (state.consultations = consultations), 
        setconsultation :(state,consultation ) => (state.consultation = consultation),
        setconsultteachers :(state,consultteachers ) => (state.consultteachers = consultteachers),
    },

    actions : {
        async get_consultations( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_consultations')
            return new Promise((resolve, reject) => {
            axios.post("/admin/consultations?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setconsultations', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async search_consultation_teachers( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'search_consultation_teachers')
            return new Promise((resolve, reject) => {
            axios.post("/admin/consultations/search_teachers?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setconsultteachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_consultation( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_consultation' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/consultations/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setconsultation', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_consultation_teachers( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_consultteachers' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/consultations/teachers/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setconsultteachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async forward_consultation_to_teachers({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'forward_consultation_to_teachers' )
            return new Promise((resolve, reject) => {
                axios.post(`/admin/consultations/forward/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setconsultations', response.data.res)
                        toast.success("Consultation Forwarded Successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_consultation( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_consultation' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/consultations/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setconsultations', response.data.res)
                toast.success("consultation Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}